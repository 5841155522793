/**
 * File industry-overview.js.
 *
 * Handles functionalty for the industry overview component.
 */

const IndustryOverview = {
  init() {
    this.container = document.querySelector(".industry-overview");
    if (!this.container) {
      return;
    }
    this.currentIndex = 0;
    this.initContainer();
  },

  initContainer() {
    this.links = [
      ...this.container.querySelectorAll(
        "[data-industry-overview-target='link']"
      ),
    ];
    this.images = [
      ...this.container.querySelectorAll(
        "[data-industry-overview-target='image']"
      ),
    ];

    this.links.forEach((link) => {
      const highlightLink = () => {
        this._pauseAutoplay();
        this._activateLink(this.links.indexOf(link));
      };

      const unhighlightLink = () => {
        this._hideImages();
        this._startAutoplay();
      };

      link.addEventListener("mouseenter", highlightLink);
      link.addEventListener("focus", highlightLink);

      link.addEventListener("mouseleave", unhighlightLink);
      link.addEventListener("blur", unhighlightLink);
    });

    this._startAutoplay();
  },

  _startAutoplay() {
    this._activateLink(this.currentIndex);

    this.interval = setInterval(() => {
      nextIndex =
        this.currentIndex < this.links.length - 1 ? this.currentIndex + 1 : 0;
      this._activateLink(nextIndex);
    }, 3000);
  },

  _pauseAutoplay() {
    this.links.forEach((links) => links.classList.remove("active"));
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  },

  _activateLink(index) {
    this.currentIndex = index;
    this.links.forEach((links) => links.classList.remove("active"));
    const link = this.links[index];
    link.classList.add("active");
    this._showRelatedImageFor(link);
  },

  _showRelatedImageFor(link) {
    this._hideImages();
    const key = link.dataset.industryKey;
    const image = this.images.find(
      (image) => image.dataset.industryKey === key
    );
    if (image) {
      image.classList.add("active");
    }
  },

  _hideImages() {
    this.images.forEach((image) => image.classList.remove("active"));
  },
};

document.addEventListener("DOMContentLoaded", () => {
  IndustryOverview.init();
});
