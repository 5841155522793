/**
 * File project-highlight.js.
 *
 * Handles toggling the project color on project detail pages
 */

const ProjectHighlight = {
  init() {
    const coverImage = document.querySelector(".project-intro .cover");
    if (!coverImage) return;

    document.body.classList.add("project-highlight");
    this._initObserver(coverImage);
  },

  _initObserver(el) {
    const observer = new IntersectionObserver(this._handleIntersection, {
      threshold: [1, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2, 0],
    });
    observer.observe(el);
  },

  _handleIntersection(entries, observer) {
    entries.forEach((entry) => {
      const currentY = entry.boundingClientRect.y;
      const currentRatio = entry.intersectionRatio;

      if (currentY < 0 && currentRatio < 0.5) {
        document.body.classList.remove("project-highlight");
      } else {
        document.body.classList.add("project-highlight");
      }
    });
  },
};

document.addEventListener("DOMContentLoaded", () => {
  ProjectHighlight.init();
});
