/**
 * File navigation.js.
 *
 * Handles toggling the navigation menu for small screens
 */

const Navigation = {
  init() {
    const header = document.querySelector(".site-header");
    const toggler = document.querySelector('button[aria-controls="site-nav"]');

    if (toggler && header) {
      toggler.addEventListener("click", () => {
        let expanded =
          "true" === toggler.getAttribute("aria-expanded") || false;
        toggler.setAttribute("aria-expanded", !expanded);
        if (expanded) {
          header.classList.remove("-open");
        } else {
          header.classList.add("-open");
        }
      });
    }
  },
};

document.addEventListener("DOMContentLoaded", () => {
  Navigation.init();
});
