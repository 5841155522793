const BeforeAfter = {
  init() {
    document
      .querySelectorAll(".before-after")
      .forEach((el) => this._initBeforeAfter(el).bind(this));
  },

  _initBeforeAfter(container) {
    const triggers = container.querySelectorAll(".toggle-option");
    triggers.forEach((trigger) =>
      trigger.addEventListener("click", this._handleTriggerClick.bind(this))
    );
    triggers[0].click();
  },

  _handleTriggerClick(evt) {
    const trigger = evt.currentTarget;
    const container = trigger.closest(".before-after");
    if (trigger.classList.contains("active")) {
      return;
    }
    const triggers = container.querySelectorAll(".toggle-option");
    const targets = container.querySelectorAll(".images > *");
    triggers.forEach((el) => el.classList.remove("active"));
    targets.forEach((el) => el.classList.remove("visible"));

    trigger.classList.add("active");
    container.querySelector(trigger.dataset.show).classList.add("visible");
  },
};

document.addEventListener("DOMContentLoaded", () => {
  BeforeAfter.init();
});
