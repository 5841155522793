const Dismissable = {
  init() {
    document
      .querySelectorAll("[data-dismissable]")
      .forEach(this._initDismissable);
  },

  _initDismissable(el) {
    const close = el.querySelector(`[aria-controls="${el.id}"]`);
    if (!close) return;
    close.addEventListener("click", () => el.parentElement.removeChild(el));
  },
};

document.addEventListener("DOMContentLoaded", () => {
  Dismissable.init();
});
