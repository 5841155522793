import tippy from "tippy.js";

const Services = {
  init() {
    document.querySelectorAll(".service").forEach((el) => this.initService(el));
  },

  initService(el) {
    const trigger = el.querySelector("button.tag");
    const details = el.querySelector(".service-details");

    if (!trigger || !details) {
      return;
    }

    tippy(trigger, {
      content: details,
      theme: "light",
      // trigger: "click",
      // hideOnClick: true,
      animation: "scale-subtle",
      duration: [150, 100],
      delay: 0,
      maxWidth: 400,
      offset: [0, 22],
    });
  },
};

document.addEventListener("DOMContentLoaded", () => {
  Services.init();
});
