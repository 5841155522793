/**
 * File heading-text-highlight.js.
 *
 * Does the text highlighting on italicized text in headings
 */

const HeadingTextHighlight = {
  init() {
    const italics = document.querySelectorAll(
      "h1 em, h2 em, h3 em, h4 em, h5 em, h6 em, .h1 em, .h2 em, .h3 em, .h4 em, .h5 em, .h6 em, .about-intro p em, .industry-intro p em, .industry-services p em, .project-details > p em, blockquote p em"
    );
    const observer = new IntersectionObserver(this._handleIntersection, {
      threshold: [1, 0.8, 0.6, 0.4, 0.2, 0],
    });

    italics.forEach((el) => {
      el.classList.add("animatable");
      observer.observe(el);
    });
  },

  _handleIntersection(entries, observer) {
    entries.forEach((entry) => {
      const el = entry.target;
      if (entry.intersectionRatio > 0.8) {
        el.classList.add("highlight");
      } else {
        el.classList.remove("highlight");
      }
    });
  },
};

document.addEventListener("DOMContentLoaded", () => {
  HeadingTextHighlight.init();
});
