/**
 * File nav-text-highlight.js.
 *
 * Handles toggling the navigation menu for small screens
 */

const NavTextHighlight = {
  init() {
    const highlightContainers = document.querySelectorAll(
      "[data-text-highlight]"
    );
    highlightContainers.forEach((container) => this.initContainer(container));
  },

  initContainer(container) {
    const targets = container.querySelectorAll("[data-text-highlight-target]");
    const triggers = container.querySelectorAll("[data-text-highlight-color]");

    triggers.forEach((trigger) => {
      trigger.addEventListener("mouseenter", (e) => {
        const color = e.currentTarget.dataset.textHighlightColor;
        targets.forEach((target) => this._setColor(target, color));
      });

      trigger.addEventListener("mouseleave", () => {
        targets.forEach((target) => this._setColor(target));
      });
    });
  },

  _setColor(target, color = null) {
    if (color) {
      target.style.setProperty("--c-text-highlight", `var(${color})`);
    } else {
      target.style.removeProperty("--c-text-highlight");
    }
  },
};

document.addEventListener("DOMContentLoaded", () => {
  NavTextHighlight.init();
});
