const DropdownMenu = {
  init() {
    document
      .querySelectorAll(".dropdown-menu")
      .forEach((container) => this.initMenu(container));
  },

  initMenu(container) {
    const trigger = container.querySelector(".menu-trigger");
    if (!trigger) return;

    trigger.addEventListener("click", this.toggleMenu.bind(this));
  },

  toggleMenu(evt) {
    // The currentTarget is `.menu-trigger`
    event.currentTarget.getAttribute("aria-expanded") === "false"
      ? this.open(evt)
      : this.close(evt);
  },

  open(evt) {
    const trigger = evt.currentTarget;
    const menu = document.getElementById(trigger.getAttribute("aria-controls"));
    if (!menu) return;

    menu.hidden = false;
    trigger.setAttribute("aria-expanded", true);
    document.addEventListener("click", this.handleOutsideClick.bind(this));
  },

  close(evt) {
    const trigger = evt.currentTarget;
    const menu = document.getElementById(trigger.getAttribute("aria-controls"));
    if (!menu) return;

    trigger.setAttribute("aria-expanded", false);
    const exited = () => {
      menu.hidden = true;
      menu.classList.remove("exiting");
      menu.removeEventListener("animationend", exited);
    };
    menu.addEventListener("animationend", exited);
    menu.classList.add("exiting");
    document.removeEventListener("click", this.handleOutsideClick);
  },

  handleOutsideClick(evt) {
    if (
      evt.target.closest(".dropdown-menu .menu, .dropdown-menu .menu-trigger")
    ) {
      return;
    }

    document
      .querySelectorAll('.menu-trigger[aria-expanded="true"]')
      .forEach((trigger) => this.close({ currentTarget: trigger }));
  },
};

document.addEventListener("DOMContentLoaded", () => {
  DropdownMenu.init();
});
