/**
 * File visibility-toggler.js.
 *
 * Handles toggling the visiblity of service items on the about page
 */

const VisibilityToggler = {
  init() {
    const container = document.querySelector("[data-toggle-targets]");
    if (container) this._initToggler(container);
  },

  _initToggler(el) {
    this.targets = [...el.querySelectorAll(el.dataset.toggleTargets)];
    this.triggers = el.querySelectorAll(".toggle-option");

    this.triggers.forEach((trigger) =>
      trigger.addEventListener("click", this._handleTriggerClick.bind(this))
    );
  },

  _handleTriggerClick(evt) {
    const trigger = evt.currentTarget;
    if (trigger.classList.contains("active")) {
      return;
    }
    this.triggers.forEach((el) => el.classList.remove("active"));
    trigger.classList.add("active");

    const targetClassNames = (trigger.dataset.hide || "")
      .split(",")
      .map((name) => `type:${name}`);

    if (targetClassNames.length) {
      this.targets.forEach((target) => {
        target.hidden = this._hasClassName(target, targetClassNames);
      });
    } else {
      this.targets.each((target) => (target.hidden = false));
    }
  },

  _hasClassName(el, arr) {
    return arr.some((className) => {
      return el.classList.contains(className);
    });
  },

  _excludesClassName(el, arr) {
    return !this._hasClassName(el, arr);
  },
};

document.addEventListener("DOMContentLoaded", () => {
  VisibilityToggler.init();
});
