const CardHelper = {
  init() {
    this.card = null;
    this.link = null;
    this.mousedowntime = 0;

    const body = document.querySelector("body");

    body.addEventListener("mousedown", this.handleCardMouseDown.bind(this));
    body.addEventListener("mouseup", this.handleCardMouseUp.bind(this));
  },

  handleCardMouseDown(evt) {
    const target = evt.target;
    this.card = target.closest('[class*="card"]');
    // Not a card? Bail out.
    if (!this.card) {
      return;
    }
    // Exclude direct clicks on links or buttons
    if (
      target.closest("a") ||
      target.closest("input") ||
      target.closest("button")
    ) {
      return;
    }
    this.link =
      this.card.querySelector("a.card--primary-link") ||
      this.card.querySelector("a");
    if (!this.link) {
      return;
    }
    this.mousedowntime = new Date().getTime();
  },

  handleCardMouseUp() {
    if (!this.link) {
      return;
    }
    const mouseuptime = new Date().getTime();
    if (mouseuptime - this.mousedowntime < 200) {
      this.link.click();
    }
  },
};

document.addEventListener("DOMContentLoaded", () => {
  CardHelper.init();
});
