/**
 * File video-hero.js.
 *
 * Handles video hero playback on the home page
 */

const VideoHero = {
  init() {
    const el = document.querySelector(".video-hero > *");
    if (el) this._initObserver(el);
  },

  _initObserver(el) {
    this.observer = new IntersectionObserver(
      this._handleIntersection.bind(this),
      {
        threshold: [1, 0.8, 0.6, 0.4, 0.2, 0],
      }
    );
    this.observer.observe(el);
  },

  _handleIntersection(entries, observer) {
    entries.forEach((entry) => {
      switch (entry.target.tagName.toLowerCase()) {
        case "img":
          if (/* entry.target.complete && */ entry.intersectionRatio > 0.3) {
            entry.target.classList.add("visible");
            this.observer.unobserve(entry.target);
          }
          break;
        case "video":
          if (entry.intersectionRatio > 0.9) {
            entry.target.play();
          }
          break;
      }
    });
  },
};

document.addEventListener("DOMContentLoaded", () => {
  VideoHero.init();
});
