const CookieBanner = {
  init() {
    this.cookieKey = "zdCookieBannerAccepted";
    if (!localStorage.getItem(this.cookieKey)) {
      this._createBanner();
    }
  },

  _createBanner() {
    const template = document.querySelector("#cookie-banner");
    if (!template) {
      return;
    }

    const bannerElements = [...template.content.cloneNode(true).children];
    if (!bannerElements.length) {
      return;
    }
    this.banner = bannerElements[0];
    this.banner
      .querySelectorAll(".close")
      .forEach((el) => el.addEventListener("click", this._accept.bind(this)));

    template.insertAdjacentElement("beforebegin", this.banner);
  },

  _accept() {
    localStorage.setItem(this.cookieKey, true);
    this.banner.parentElement.removeChild(this.banner);
  },
};

document.addEventListener("DOMContentLoaded", () => {
  CookieBanner.init();
});
